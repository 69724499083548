
import process_vars from "../../intermediate/process_vars";
import { process_table_vars } from "./InputTable";
let math = require('mathjs');

// contains all recursive functions needed to interpret AtlasScript

const usDateChecker = /(0[1-9]|1[012])[- \/.](0[1-9]|[12][0-9]|3[01])[- \/.](19|20)\d\d/;
const ukDateChecker = /(0[1-9]|[12][0-9]|3[01])[- \/.](0[1-9]|1[012])[- \/.](19|20)\d\d/;


function isUSDate(dateInput) {
    try {
        if (dateInput.length === 10) {
            // MM/DD/YYYY
            let month = parseInt(dateInput.substring(0, 2));
            let day = parseInt(dateInput.substring(3, 5));
            let year = parseInt(dateInput.substring(6, 10));

            let isUSDate = (1 <= month && month <= 12) && (1 <= day && day <= 31);
            return isUSDate;
        } else {
            return false;
        }
    } catch (e) {
        return false;
    }
}

function isUKDate(dateInput) {
    try {
        if (dateInput.length === 10) {
            // DD/MM/YYYY
            let month = parseInt(dateInput.substring(3, 5));
            let day = parseInt(dateInput.substring(0, 2));
            let year = parseInt(dateInput.substring(6, 10));

            let isUKDate = (1 <= month && month <= 12) && (1 <= day && day <= 31);
            return isUKDate;
        } else {
            return false;
        }
    } catch (e) {
        return false;
    }
}



function outputOf(element, id, comps, vars, externals, row = 0) {
    if ([
        "expression/boolean",
        "operator/logical/and",
        "operator/logical/or",
        "operator/logical/not",
        "operator/ordinal/g",
        "operator/ordinal/geq",
        "operator/ordinal/l",
        "operator/ordinal/leq",
        "operator/ordinal/eq",
        "operator/type/isNum",
        "operator/type/isStr",
        "operator/array/includes",
        "operator/array/isEmpty",
        "operator/qual/digits",
        "operator/type/isEmail",
        "operator/type/isDate",
        "operator/type/isBlank"
    ].includes(element.type)) {
        return {
            type: "Boolean",
            value: evaluateBoolean(element, id, comps, vars, externals, row)
        };
    } else if ([
        "operator/quant/add",
        "operator/quant/subtract",
        "operator/quant/multiply",
        "operator/quant/divide",
        "operator/quant/modulus",
        "operator/quant/floor",
        "operator/quant/ceiling",
        "operator/quant/round",
        "operator/quant/abs",
        "operator/quant/max",
        "operator/quant/min",
        "operator/qual/words",
        "operator/qual/charLength",
        "operator/qual/day",
        "operator/qual/month",
        "operator/qual/year"
    ].includes(element.type)) {
        return {
            type: "Number",
            value: evaluateNumber(element, id, comps, vars, externals, row)
        };
    } else if ([
        "conditional/if",
        "variable/set",
        "signal/error",
        "signal/disable"
    ].includes(element.type)) {
        return {
            type: "Void",
            value: null
        };
    } else if (element.type === "variable/get") {
        for (let k = 0; k < vars.length; ++k) {
            let variable = vars[k];
            if (variable.name === element.var) {
                if (variable.type === "Number") {
                    try {
                        let parsedNumber = variable.value;

                        // regex for checking for an expression in form: [{x} + {y}]f
                        let mathRegex = /\[(.*?)\](\d*)f(\d*)/g;
                        let matchResults = [...parsedNumber.matchAll(mathRegex)];

                        if (matchResults.length > 0) {
                            matchResults.forEach(result => {
                                let mathExp = process_vars(result[1], vars);
                                let evaluatedExp = 0;

                                // there may be evaluation errors via mathjs, so we use try/catch
                                try {
                                    // we use math.js here
                                    evaluatedExp = math.evaluate(mathExp).toString();
                                } catch (e) {
                                    // if error is found, don't do anything
                                }

                                // we replace the expression with its evaluated form
                                parsedNumber = parsedNumber.replace(result[0], evaluatedExp);
                            });
                        }
                        return {
                            type: "Number",
                            value: parseFloat(parsedNumber) === NaN ? 0 : parseFloat(parsedNumber)
                        };
                    } catch (e) {
                        // proceed below
                    }
                    return {
                        type: "Number",
                        value: parseFloat(variable.value) === NaN ? 0 : parseFloat(variable.value)
                    };
                } else if (variable.type === "Boolean") {
                    return {
                        type: "Boolean",
                        value: variable.value === "true" ? true : false
                    };
                } else {
                    return {
                        type: variable.type,
                        value: variable.value
                    };
                }
            }
        }
    } else if (element.type === "flags/get") {
        try {
            let indexOfFlag = -1;
            for (let k = 0; k < externals.flags.length; ++k) {
                if (externals.flags[k].name === element.flag) {
                    indexOfFlag = k;
                    break;
                }
            } if (indexOfFlag === -1) {
                return {
                    type: "String",
                    value: "ERROR: Flag Not Found"
                };
            }
            let flagValue = externals.flags[indexOfFlag].values[parseInt(externals.flags[indexOfFlag].value)];
            return {
                type: "String",
                value: flagValue.toString()
            };
        } catch (e) {
            return {
                type: "String",
                value: "ERROR: Flag Value Could Not Be Fetched"
            };
        }
    } else if (element.type === "variable/table/get") {
        for (let k = 0; k < comps[id].vars[row].length; ++k) {
            let variable = comps[id].vars[row][k];
            if (variable.name === comps[id].vars[0][parseInt(element.var)].name) {
                if (variable.type === "Number") {
                    try {
                        let parsedNumber = variable.value;

                        // regex for checking for an expression in form: [{x} + {y}]f
                        let mathRegex = /\[(.*?)\](\d*)f(\d*)/g;
                        let matchResults = [...parsedNumber.matchAll(mathRegex)];

                        if (matchResults.length > 0) {
                            matchResults.forEach(result => {
                                let mathExp = process_table_vars(comps, id, result[1], row);
                                let evaluatedExp = 0;

                                // there may be evaluation errors via mathjs, so we use try/catch
                                try {
                                    // we use math.js here
                                    evaluatedExp = math.evaluate(mathExp).toString();
                                } catch (e) {
                                    // if error is found, don't do anything
                                }

                                // we replace the expression with its evaluated form
                                parsedNumber = parsedNumber.replace(result[0], evaluatedExp);
                            });
                        }
                        return {
                            type: "Number",
                            value: parseFloat(parsedNumber) === NaN ? 0 : parseFloat(parsedNumber)
                        };
                    } catch (e) {
                        // proceed below
                    }

                    return {
                        type: "Number",
                        value: parseFloat(variable.value) === NaN ? 0 : parseFloat(variable.value)
                    };
                } else if (variable.type === "Boolean") {
                    return {
                        type: "Boolean",
                        value: variable.value === "true" ? true : false
                    };
                } else {
                    return {
                        type: variable.type,
                        value: variable.value
                    };
                }
            }
        }
    } else if (element.type === "expression/number") {
        return {
            type: "Number",
            value: parseFloat(element.value) === NaN ? 0 : parseFloat(element.value)
        };
    } else if (element.type === "expression/string") {
        return {
            type: "String",
            value: element.value
        };
    } else if (element.type === "expression/color") {
        return {
            type: "Color",
            value: element.value
        };
    } else if (element.type === "self/value") {
        if (comps[id].type === "primitive/Input") {
            if (isNaN(comps[id].props.value)) {
                return {
                    type: "String",
                    value: comps[id].props.value
                };
            } else {
                return {
                    type: "Number",
                    value: parseFloat(comps[id].props.value) === NaN ? 0 : parseFloat(comps[id].props.value)
                };
            }
        } else if (comps[id].type === "primitive/Check") {
            let arrayValue = [];
            for (let k = 0; k < comps[id].props.selected.length; ++k) {
                arrayValue.push(comps[id].props.content[parseInt(comps[id].props.selected[k])]);
            }
            return {
                type: "Array",
                value: arrayValue
            };
        } else if (comps[id].type === "primitive/Radio" || comps[id].type === "primitive/Drop") {
            if (isNaN(comps[id].props.content[parseInt(comps[id].props.selected)])) {
                return {
                    type: "String",
                    value: comps[id].props.content[parseInt(comps[id].props.selected)]
                };
            } else {
                return {
                    type: "Number",
                    value: parseFloat(comps[id].props.content[parseInt(comps[id].props.selected)]) === NaN ? 0 : parseFloat(comps[id].props.content[parseInt(comps[id].props.selected)])
                };
            }
        }
    } else if (element.type === "self/table/value") {
        let value = comps[id].data[row][parseInt(element.column)];
        if (comps[id].colData[parseInt(element.column)].type === "Input" || comps[id].colData[parseInt(element.column)].type === "Label") {
            if (isNaN(value)) {
                return {
                    type: "String",
                    value: value
                };
            } else {
                return {
                    type: "Number",
                    value: parseFloat(value) === NaN ? 0 : parseFloat(value)
                };
            }
        } else if (comps[id].colData[parseInt(element.column)].type === "Check") {
            return {
                type: "Boolean",
                value: comps[id].data[row][parseInt(element.column)]
            };
        } else if (comps[id].colData[parseInt(element.column)].type === "Drop") {
            if (isNaN(comps[id].colData[element.column].values[parseInt(comps[id].data[row][parseInt(element.column)])])) {
                return {
                    type: "String",
                    value: comps[id].colData[element.column].values[parseInt(comps[id].data[row][parseInt(element.column)])]
                };
            } else {
                return {
                    type: "Number",
                    value: parseFloat(comps[id].colData[element.column].values[parseInt(comps[id].data[row][parseInt(element.column)])]) === NaN ? 0 : parseFloat(comps[id].colData[element.column].values[parseInt(comps[id].data[row][parseInt(element.column)])])
                };
            }
        }
    } else {
        return {
            type: "Void",
            value: null
        };
    }
}

function evaluateNumber(element, id, comps, vars, externals, row = 0) {
    try {
        if (element.type === 'expression/number') {
            if (!isNaN(element.value)) {
                return parseFloat(element.value);
            } else {
                return 0.0;
            }
        } else if (element.type === 'variable/get') {
            for (let k = 0; k < vars.length; ++k) {
                let variable = vars[k];
                if (variable.name === element.var && variable.type === "Number") {
                    let parsedValue = variable.value.toString();
                    try {
                        // regex for checking for an expression in form: [{x} + {y}]f
                        let mathRegex = /\[(.*?)\](\d*)f(\d*)/g;
                        let matchResults = [...parsedValue.matchAll(mathRegex)];

                        if (matchResults.length > 0) {
                            matchResults.forEach(result => {
                                let mathExp = process_vars(result[1], vars);
                                let evaluatedExp = 0;

                                // there may be evaluation errors via mathjs, so we use try/catch
                                try {
                                    // we use math.js here
                                    evaluatedExp = math.evaluate(mathExp).toString();
                                } catch (e) {
                                    // if error is found, don't do anything
                                }

                                // we replace the expression with its evaluated form
                                parsedValue = parsedValue.replace(result[0], evaluatedExp);
                            });
                        }

                        return parseFloat(parsedValue) === NaN ? 0 : parseFloat(parsedValue);
                    } catch (e) {
                        // proceed below
                    }

                    return parseFloat(parsedValue);
                }
            }
        } else if (element.type === 'variable/table/get') {
            for (let k = 0; k < comps[id].vars[row].length; ++k) {
                let variable = comps[id].vars[row][k];
                if (variable.name === comps[id].vars[0][parseInt(element.var)].name && variable.type === "Number") {
                    let parsedValue = variable.value.toString();
                    try {
                        // regex for checking for an expression in form: [{x} + {y}]f
                        let mathRegex = /\[(.*?)\](\d*)f(\d*)/g;
                        let matchResults = [...parsedValue.matchAll(mathRegex)];

                        if (matchResults.length > 0) {
                            matchResults.forEach(result => {
                                let mathExp = process_table_vars(comps, id, result[1], row);
                                let evaluatedExp = 0;

                                // there may be evaluation errors via mathjs, so we use try/catch
                                try {
                                    // we use math.js here
                                    evaluatedExp = math.evaluate(mathExp).toString();
                                } catch (e) {
                                    // if error is found, don't do anything
                                }

                                // we replace the expression with its evaluated form
                                parsedValue = parsedValue.replace(result[0], evaluatedExp);
                            });
                        }

                        return parseFloat(parsedValue) === NaN ? 0 : parseFloat(parsedValue);
                    } catch (e) {
                        // proceed below
                    }

                    return parseFloat(parsedValue);
                }
            } return 0.0;
        } else if (element.type === 'self/value') {
            if (comps[id].type === 'primitive/Input') {
                if (!isNaN(comps[id].props.value)) {
                    return parseFloat(comps[id].props.value);
                } else {
                    return 0.0;
                }
            } else if (comps[id].type === 'primitive/Radio' || comps[id].type === 'primitive/Drop') {
                if (!isNaN(comps[id].props.content[parseInt(comps[id].props.selected)])) {
                    return parseFloat(comps[id].props.content[parseInt(comps[id].props.selected)]);
                } else {
                    return 0.0;
                }
            } else {
                return 0.0;
            }
        } else if (element.type === 'self/table/value') {
            let value = comps[id].data[row][parseInt(element.column)];
            if (comps[id].colData[parseInt(element.column)].type === "Input" || comps[id].colData[parseInt(element.column)].type === "Label") {
                if (isNaN(value)) {
                    return 0.0;
                } else {
                    return parseFloat(value) === NaN ? 0 : parseFloat(value)
                }
            } else if (comps[id].colData[parseInt(element.column)].type === "Drop") {
                if (isNaN(comps[id].colData[element.column].values[parseInt(comps[id].data[row][parseInt(element.column)])])) {
                    return 0.0;
                } else {
                    return parseFloat(comps[id].colData[element.column].values[parseInt(comps[id].data[row][parseInt(element.column)])]) === NaN ? 0 : parseFloat(comps[id].colData[element.column].values[parseInt(comps[id].data[row][parseInt(element.column)])]);
                }
            }
        } else if (element.type === 'operator/quant/add') {
            let condition1 = element.children[0].children[0];
            let condition2 = element.children[1].children[0];

            // console.log(condition1);
            // console.log(condition2);

            console.log(parseFloat(evaluateNumber(condition1, id, comps, vars, externals, row).toString()));
            console.log(parseFloat(evaluateNumber(condition2, id, comps, vars, externals, row).toString()));

            return (
                parseFloat(evaluateNumber(condition1, id, comps, vars, externals, row).toString()) +
                parseFloat(evaluateNumber(condition2, id, comps, vars, externals, row).toString())
            ).toFixed(9);
        } else if (element.type === 'operator/quant/subtract') {
            let condition1 = element.children[0].children[0];
            let condition2 = element.children[1].children[0];
            return (
                parseFloat(evaluateNumber(condition1, id, comps, vars, externals, row).toString()) -
                parseFloat(evaluateNumber(condition2, id, comps, vars, externals, row).toString())
            ).toFixed(9);
        } else if (element.type === 'operator/quant/multiply') {
            let condition1 = element.children[0].children[0];
            let condition2 = element.children[1].children[0];
            return (
                parseFloat(evaluateNumber(condition1, id, comps, vars, externals, row).toString()) *
                parseFloat(evaluateNumber(condition2, id, comps, vars, externals, row).toString())
            ).toFixed(9);
        } else if (element.type === 'operator/quant/divide') {
            let condition1 = element.children[0].children[0];
            let condition2 = element.children[1].children[0];
            return (
                parseFloat(evaluateNumber(condition1, id, comps, vars, externals, row).toString()) /
                parseFloat(evaluateNumber(condition2, id, comps, vars, externals, row).toString())
            ).toFixed(9);
        } else if (element.type === 'operator/quant/modulus') {
            let condition1 = element.children[0].children[0];
            let condition2 = element.children[1].children[0];
            return Math.round(evaluateNumber(condition1, id, comps, vars, externals, row)) % Math.round(evaluateNumber(condition2, id, comps, vars, externals, row));
        } else if (element.type === "operator/quant/floor") {
            let condition = evaluateNumber(element.children[0].children[0], id, comps, vars, externals, row);
            return Math.floor(condition);
        } else if (element.type === "operator/quant/ceiling") {
            let condition = evaluateNumber(element.children[0].children[0], id, comps, vars, externals, row);
            return Math.ceil(condition);
        } else if (element.type === "operator/quant/round") {
            let condition = evaluateNumber(element.children[0].children[0], id, comps, vars, externals, row);
            return Math.round(condition);
        } else if (element.type === "operator/quant/abs") {
            let condition = evaluateNumber(element.children[0].children[0], id, comps, vars, externals, row);
            return Math.abs(condition).toFixed(9);
        } else if (element.type === "operator/quant/max") {
            let conditions = [];
            for (let k = 0; k < element.numOfChildren; ++k) {
                conditions.push(
                    evaluateNumber(element.children[k].children[0], id, comps, vars, externals, row)
                );
            } return Math.max(...conditions);
        } else if (element.type === "operator/quant/min") {
            let conditions = [];
            for (let k = 0; k < element.numOfChildren; ++k) {
                conditions.push(
                    evaluateNumber(element.children[k].children[0], id, comps, vars, externals, row)
                );
            } return Math.min(...conditions);
        }

        // START
        // Unsure why this is here. Not currently causing any issues, so will keep this bit here
        // unless shown to be problematic. Useless code branch, at worst. Strange edge-case condition
        // at best.
        else if (element.type === "operator/qual/digits") {
            let condition = outputOf(element.children[0].children[0], id, comps, vars, externals, row);
            if (condition.type === "String") {
                return condition.value.length;
            } else {
                return 0;
            }
        }
        // END

        else if (element.type === "operator/qual/words") {
            let condition = outputOf(element.children[0].children[0], id, comps, vars, externals, row);
            if (condition.type === "String") {
                return condition.value.trim().split(/\s+/).length;
            } else {
                return 0;
            }
        }

        else if (element.type === "operator/qual/charLength") {
            let consideredValue = '';
            if (element.children[0].children[0].type === "self/value") {
                consideredValue = comps[id].props.value;
            } else {
                let condition = outputOf(element.children[0].children[0], id, comps, vars, externals, row);
                consideredValue = condition.value.toString();
            }
            if (element.hasOwnProperty('whitespace')) {
                if (element.whitespace === "count") {
                    console.log(consideredValue);
                    return consideredValue.length;
                } else if (element.whitespace === "nocount") {
                    return consideredValue.split('').filter(t => {
                        if (t !== ' ') {
                            return t;
                        } else {
                            return null;
                        }
                    }).length
                } else {
                    return consideredValue.length;
                }
            } else {
                return consideredValue.length;
            }
        }

        else if (element.type === "operator/qual/day") {
            let condition = outputOf(element.children[0].children[0], id, comps, vars, externals, row);
            let format = 'us';

            try {
                if (element.hasOwnProperty('format')) {
                    if (element.format === 'us') {
                        format = 'us';
                    } else {
                        format = 'uk';
                    }
                } else {
                    format = 'us';
                }
            } catch (e) {
                format = 'us';
            }

            if (condition.type === 'String') {
                if (isUSDate(condition.value) && format === 'us') {
                    return parseInt(condition.value[3] + condition.value[4]);
                } else if (isUKDate(condition.value) && format === 'uk') {
                    return parseInt(condition.value[0] + condition.value[1]);
                } else {
                    return 0;
                }
            }
        } else if (element.type === "operator/qual/month") {
            let condition = outputOf(element.children[0].children[0], id, comps, vars, externals, row);
            let format = 'us';

            try {
                if (element.hasOwnProperty('format')) {
                    if (element.format === 'us') {
                        format = 'us';
                    } else {
                        format = 'uk';
                    }
                } else {
                    format = 'us';
                }
            } catch (e) {
                format = 'us';
            }

            if (condition.type === 'String') {
                if (isUSDate(condition.value) && format === 'us') {
                    return parseInt(condition.value[0] + condition.value[1]);
                } else if (isUKDate(condition.value) && format === 'uk') {
                    return parseInt(condition.value[3] + condition.value[4]);
                } else {
                    return 0;
                }
            }
        } else if (element.type === "operator/qual/year") {
            let condition = outputOf(element.children[0].children[0], id, comps, vars, externals, row);
            if (condition.type === "String" && (
                isUKDate(condition.value) || isUKDate(condition.value)
            )) {
                return parseInt(condition.value[6] + condition.value[7] + condition.value[8] + condition.value[9]);
            } else {
                return 0;
            }
        }
        else {
            return 0.0;
        }
    } catch (err) {
        return 0.0;
    }
}

function evaluateBoolean(element, id, comps, vars, externals, row = 0) {
    try {
        if (element.type === 'expression/boolean') {
            if (element.value === "true") {
                return true;
            } else {
                return false;
            }
        } else if (element.type === 'variable/get') {
            for (let k = 0; k < vars.length; ++k) {
                let variable = vars[k];
                if (variable.name === element.var && variable.type === "Boolean") {
                    if (variable.value === 'true') {
                        return true;
                    }
                }
            } return false;
        } else if (element.type === 'variable/table/get') {
            for (let k = 0; k < comps[id].vars[row].length; ++k) {
                let variable = comps[id].vars[row][k];
                if (variable.name === element.var && variable.type === "Boolean") {
                    if (variable.value === 'true') {
                        return true;
                    }
                }
            } return false;
        } else if (element.type === 'self/table/value') {
            if (comps[id].colData[parseInt(element.column)].type === 'Check') {
                return comps[id].data[row][parseInt(element.column)];
            }
        } else if (element.type === 'operator/logical/and') {
            let condition1 = element.children[0].children[0];
            let condition2 = element.children[1].children[0];
            return evaluateBoolean(condition1, id, comps, vars, externals, row) && evaluateBoolean(condition2, id, comps, vars, externals, row);
        } else if (element.type === 'operator/logical/or') {
            let condition1 = element.children[0].children[0];
            let condition2 = element.children[1].children[0];
            return evaluateBoolean(condition1, id, comps, vars, externals, row) || evaluateBoolean(condition2, id, comps, vars, externals, row);
        } else if (element.type === 'operator/logical/not') {
            let condition = element.children[0].children[0];
            return !evaluateBoolean(condition, id, comps, vars, externals, row);
        } else if (element.type === 'operator/ordinal/g') {
            let condition1 = element.children[0].children[0];
            let condition2 = element.children[1].children[0];
            return evaluateNumber(condition1, id, comps, vars, externals, row) > evaluateNumber(condition2, id, comps, vars, externals, row);
        } else if (element.type === 'operator/ordinal/geq') {
            let condition1 = element.children[0].children[0];
            let condition2 = element.children[1].children[0];

            console.log(evaluateNumber(condition1, id, comps, vars, externals, row));
            console.log(evaluateNumber(condition2, id, comps, vars, externals, row));


            return evaluateNumber(condition1, id, comps, vars, externals, row) >= evaluateNumber(condition2, id, comps, vars, externals, row);
        } else if (element.type === 'operator/ordinal/l') {
            let condition1 = element.children[0].children[0];
            let condition2 = element.children[1].children[0];
            return evaluateNumber(condition1, id, comps, vars, externals, row) < evaluateNumber(condition2, id, comps, vars, externals, row);
        } else if (element.type === 'operator/ordinal/leq') {
            let condition1 = element.children[0].children[0];
            let condition2 = element.children[1].children[0];
            return evaluateNumber(condition1, id, comps, vars, externals, row) <= evaluateNumber(condition2, id, comps, vars, externals, row);
        } else if (element.type === 'operator/ordinal/eq') {
            let condition1 = element.children[0].children[0];
            let condition2 = element.children[1].children[0];
            condition1 = outputOf(condition1, id, comps, vars, externals, row);
            condition2 = outputOf(condition2, id, comps, vars, externals, row);

            // console.log("=== LOGGING EQUALITY ===");
            // console.log(condition1);
            // console.log(condition2);

            if (condition1.type === condition2.type && condition1.type !== "Void") {
                if (condition1.type === "Number") {
                    return Math.abs(condition1.value - condition2.value) < 0.0000001;
                } else {
                    return condition1.value === condition2.value;
                }
            } else {
                return false;
            }
        } else if (element.type === "operator/type/isNum") {
            let condition = outputOf(element.children[0].children[0], id, comps, vars, externals, row);
            if (condition.type === "Number" && !isNaN(condition.value)) {
                return true;
            } else {
                return false;
            }
        } else if (element.type === "operator/type/isStr") {
            let condition = element.children[0].children[0];
            if (outputOf(condition, id, comps, vars, externals, row).type === "String") {
                return true;
            } else {
                return false;
            }
        } else if (element.type === "operator/array/includes") {
            if (comps[id].type === "primitive/Check") {
                let condition1 = element.children[0].children[0];
                let condition2 = element.children[1].children[0];
                condition1 = outputOf(condition1, id, comps, vars, externals, row);
                condition2 = outputOf(condition2, id, comps, vars, externals, row);
                if (condition1.type === "Array" && condition2.type === "String") {
                    return condition1.value.includes(condition2.value);
                } else {
                    return false;
                }
            } else {
                return false;
            }
        } else if (element.type === "operator/array/isEmpty") {
            let condition = outputOf(element.children[0].children[0], id, comps, vars, externals, row);
            if (condition.type === "Array") {
                return condition.value.length === 0;
            }
            else if (condition.type === "String" || condition.type === "Number") {
                return condition.value === undefined;
            }
            else {
                return true;
            }
        } else if (element.type === "operator/qual/digits") {
            if (element.children[0].children[0].type === "self/value") {
                if (comps[id].type === "primitive/Input") {
                    let selfValue = comps[id].props.value;
                    if (!comps[id].props.value.includes('.')) {
                        selfValue = selfValue + ".";
                    }

                    let before = selfValue.split('.')[0].length;
                    let after = selfValue.split('.')[1].length;
                    if (isNaN(element.before) || element.before === null) {
                        if (isNaN(element.after) || element.after === null) {
                            return true;
                        } else {
                            return element.after === after || parseInt(element.after) < 0;
                        }
                    } else {
                        if (isNaN(element.after) || element.after === null) {
                            return element.before === before || parseInt(element.before) < 0;
                        } else {
                            return (element.before === before || parseInt(element.before) < 0) && element.after === after;
                        }
                    }
                } else {
                    return false;
                }
            } else {
                return false;
            }
        } else if (element.type === "operator/type/isEmail") {
            let condition = outputOf(element.children[0].children[0], id, comps, vars, externals, row);
            if (condition.type === "String") {
                return /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9]))\.){3}(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9])|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/.test(condition.value);
            } else {
                return false;
            }
        } else if (element.type === "operator/type/isDate") {
            // let usDateChecker = /(0[1-9]|1[012])[- \/.](0[1-9]|[12][0-9]|3[01])[- \/.](19|20)\d\d/;
            // let ukDateChecker = /(0[1-9]|[12][0-9]|3[01])[- \/.](0[1-9]|1[012])[- \/.](19|20)\d\d/;
            let selectedDateChecker = 'us';

            try {
                if (element.hasOwnProperty('format')) {
                    switch (element.format) {
                        case 'us':
                            selectedDateChecker = 'us';
                            break;
                        case 'uk':
                            selectedDateChecker = 'uk';
                            break;
                        default:
                            selectedDateChecker = 'us';
                    }
                } else {
                    selectedDateChecker = 'us';
                }
            } catch (e) {
                selectedDateChecker = 'us';
            }

            let condition = outputOf(element.children[0].children[0], id, comps, vars, externals, row);
            if (condition.type === "String") {
                if (selectedDateChecker == 'us') {
                    if (condition.value.length == 10) {

                        let splitDateFirst = condition.value.substring(0, 2);
                        let splitDateSecond = condition.value.substring(3, 5);
                        let splitDateThird = condition.value.substring(6, 10);

                        if (1 <= parseInt(splitDateFirst) &&
                            parseInt(splitDateFirst) <= 12) {
                            if (1 <= parseInt(splitDateSecond) &&
                                parseInt(splitDateSecond) <= 31) {
                                return !isNaN(parseInt(splitDateThird));
                            }
                        }

                        return false;
                    } else {
                        return false;
                    }
                } else {
                    if (condition['value'].toString().length == 10) {
                        let splitDateFirst = condition.value.substring(0, 2);
                        let splitDateSecond = condition.value.substring(3, 5);
                        let splitDateThird = condition.value.substring(6, 10);

                        if (1 <= parseInt(splitDateSecond) &&
                            parseInt(splitDateSecond) <= 12) {
                            if (1 <= parseInt(splitDateFirst) &&
                                parseInt(splitDateFirst) <= 31) {
                                return !isNaN(parseInt(splitDateThird));
                            }
                        }

                        return false;
                    } else {
                        return false;
                    }
                }
            } else {
                return false;
            }
        } else if (element.type === "operator/type/isBlank") {
            let condition = outputOf(element.children[0].children[0], id, comps, vars, externals, row);
            if (condition.type === "String") {
                return false;
            } else if (condition.type === "Number") {
                return isNaN(condition.value);
            } else {
                return false;
            }
        } else if (element.type === "operator/type/isTime") {
            // ^(?:(?:([01]?\d|2[0-3]):)?([0-5]?\d):)?([0-5]?\d)$
            let condition = outputOf(element.children[0].children[0], id, comps, vars, externals, row);
            if (condition.type === "String") {
                // return /^(?:(?:([01]?\d|2[0-3]):)?([0-5]?\d):)?([0-5]?\d)$/.test(condition.value);
                return /^([0-9]|0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]$/.test(condition.value);
            } else {
                return false;
            }
        } else if (element.type === "operator/type/isUndecorated") {
            let condition = outputOf(element.children[0].children[0], id, comps, vars, externals, row);
            if (condition.type === "Number" && !isNaN(condition.value)) {
                return false;
            } else {
                if (condition.type === "String" && /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9]))\.){3}(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9])|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/.test(condition.value)) {
                    return false;
                } else {
                    if (condition.type === "String" && /(0[1-9]|1[012])[- \/.](0[1-9]|[12][0-9]|3[01])[- \/.](19|20)\d\d/.test(condition.value)) {
                        return false;
                    } else {
                        if (condition.type === "String" && /^(?:(?:([01]?\d|2[0-3]):)?([0-5]?\d):)?([0-5]?\d)$/.test(condition.value)) {
                            return false;
                        } else {
                            return true;
                        }
                    }
                }
            }
        } else {
            return false;
        }
    } catch (err) {
        return false;
    }
}

function evaluate(children, id, comps, setComps, vars, setVars, externals, setExternals, row = 0) {

    try {
        for (let k = 0; k < children.length; ++k) {
            if (children[k].type === 'conditional/if') {
                let condition = children[k].children[0].children[0];
                let thenStatement = children[k].children[1].children;
                let elseStatement = children[k].children[2].children;
                if (evaluateBoolean(condition, id, comps, vars, externals)) {
                    evaluate(thenStatement, id, comps, setComps, vars, setVars, externals, setExternals);
                } else {
                    evaluate(elseStatement, id, comps, setComps, vars, setVars, externals, setExternals);
                }
            } else if (children[k].type === "variable/set") {
                let futureVariable = outputOf(children[k].children[0], id, comps, vars, externals, row);
                for (let j = 0; j < vars.length; ++j) {
                    if (futureVariable.type === vars[j].type && children[k].var === vars[j].name) {
                        let new_vars = [...vars];
                        new_vars[j].value = futureVariable.value.toString();
                        setVars(new_vars);
                        break;
                    }
                }
            } else if (children[k].type === "flags/setToChild") {
                let futureFlag = outputOf(children[k].children[0], id, comps, vars, externals, row);
                futureFlag = futureFlag.value.toString();

                let flag = children[k].flag;
                let value = futureFlag;
                let new_externals = { ...externals };

                let whichFlagIndex = 0;
                for (let k = 0; k < externals.flags.length; ++k) {
                    if (externals.flags[k].name === flag) {
                        whichFlagIndex = k;
                        break;
                    }
                }

                let whichValueIndex = -1;
                for (let k = 0; k < externals.flags[whichFlagIndex].values.length; ++k) {
                    if (externals.flags[whichFlagIndex].values[k] === value) {
                        whichValueIndex = k;
                        break;
                    }
                }

                if (whichValueIndex !== -1) {
                    new_externals.flags[whichFlagIndex].value = whichValueIndex;
                    setExternals(new_externals);
                }
            } else if (children[k].type === "flags/set") {
                // console.log("FLAG SET");
                // console.log(children[k]);
                // console.log(externals);
                let flag = children[k].flag;
                let value = children[k].value;
                let new_externals = { ...externals };

                let whichFlagIndex = 0;
                for (let k = 0; k < externals.flags.length; ++k) {
                    if (externals.flags[k].name === flag) {
                        whichFlagIndex = k;
                        break;
                    }
                }

                let whichValueIndex = 0;
                for (let k = 0; k < externals.flags[whichFlagIndex].values.length; ++k) {
                    if (externals.flags[whichFlagIndex].values[k] === value) {
                        whichValueIndex = k;
                        break;
                    }
                }

                new_externals.flags[whichFlagIndex].value = whichValueIndex;

                setExternals(new_externals);
            } else if (children[k].type === "signal/error") {
                // alert(children[k].message);
                if (comps[id].type !== "intermediate/InputTable") {
                    let new_comps = [...comps];
                    if (children[k].message !== "") {
                        new_comps[id].error = children[k].message;
                    } else {
                        new_comps[id].error = "";
                    }
                    setComps(new_comps);
                }
            }
            else if (children[k].type === "signal/warning") {
                if (comps[id].type !== "intermediate/InputTable") {
                    let new_comps = [...comps];
                    if (children[k].message !== '') {
                        new_comps[id].warning = children[k].message;
                    } else {
                        new_comps[id].warning = '';
                    }
                    setComps(new_comps);
                }
            }
            else if (children[k].type === "signal/disable") {
                if (comps[id].type !== "intermediate/InputTable" && comps[parseInt(children[k].comp)].type !== "intermediate/InputTable") {
                    let new_comps = [...comps];
                    new_comps[parseInt(children[k].comp)].disable = true;

                    // clear the target element's value on disable
                    switch (new_comps[parseInt(children[k].comp)].type) {
                        case 'primitive/Check':
                            new_comps[parseInt(children[k].comp)].props.selected = [];
                            break;
                        case 'primitive/Drop':
                            new_comps[parseInt(children[k].comp)].props.selected = '0';
                            break;
                        case 'primitive/Input':
                            new_comps[parseInt(children[k].comp)].props.value = '';
                            break;
                        case 'primitive/Radio':
                            new_comps[parseInt(children[k].comp)].props.selected = '';
                            break;
                        default:
                            break;
                    }

                    setComps(new_comps);
                }
            }
            else if (children[k].type === "signal/disableSelf") {
                let new_comps = [...comps];
                new_comps[id].disable = true;

                // clear the value of the current element on disableSelf
                switch (new_comps[id].type) {
                    case 'primitive/Check':
                        new_comps[id].props.selected = [];
                        break;
                    case 'primitive/Drop':
                        new_comps[id].props.selected = '0';
                        break;
                    case 'primitive/Input':
                        new_comps[id].props.value = '';
                        break;
                    case 'primitive/Radio':
                        new_comps[id].props.selected = '';
                        break;
                    default:
                        break;
                }

                setComps(new_comps);
            } else if (children[k].type === "signal/enable") {
                if (comps[id].type !== "intermediate/InputTable" && comps[parseInt(children[k].comp)].type !== "intermediate/InputTable") {
                    let new_comps = [...comps];
                    new_comps[parseInt(children[k].comp)].disable = false;
                    setComps(new_comps);
                }
            }
        }
    } catch (err) {
        console.error("AtlasScript Compilation Error > ", err);
    }

}

function evaluateTableRow(children, id, comps, setComps, vars, setVars, externals, setExternals, row = 0) {

    try {
        for (let k = 0; k < children.length; ++k) {
            if (children[k].type === 'conditional/if') {
                let condition = children[k].children[0].children[0];
                let thenStatement = children[k].children[1].children;
                let elseStatement = children[k].children[2].children;
                if (evaluateBoolean(condition, id, comps, vars, externals, row)) {
                    evaluateTableRow(thenStatement, id, comps, setComps, vars, setVars, externals, setExternals, row);
                } else {
                    evaluateTableRow(elseStatement, id, comps, setComps, vars, setVars, externals, setExternals, row);
                }
            } else if (children[k].type === "variable/table/set") {
                let futureVariable = outputOf(children[k].children[0], id, comps, vars, externals, row);
                for (let j = 0; j < comps[id].vars[row].length; ++j) {
                    if (futureVariable.type === comps[id].vars[row][j].type && comps[id].vars[row][parseInt(children[k].var)].name === comps[id].vars[row][j].name) {
                        let new_comps = [...comps];
                        new_comps[id].vars[row][j].value = futureVariable.value.toString();
                        setComps(new_comps);
                        break;
                    }
                }
            } else if (children[k].type === "signal/table/error") {
                // alert(children[k].message);
                let new_comps = [...comps];
                if (children[k].message !== "") {
                    new_comps[id].error[row][parseInt(children[k].column)] = children[k].message;
                } else {
                    new_comps[id].error[row][parseInt(children[k].column)] = "";
                }
                setComps(new_comps);
            } else if (children[k].type === "signal/table/warning") {
                // alert(children[k].message);
                let new_comps = [...comps];
                if (children[k].message !== "") {
                    new_comps[id].warning[row][parseInt(children[k].column)] = children[k].message;
                } else {
                    new_comps[id].warning[row][parseInt(children[k].column)] = "";
                }
                setComps(new_comps);
            }
            else if (children[k].type === "signal/table/disable") {
                let new_comps = [...comps];
                new_comps[id].disable[row][parseInt(children[k].column)] = true;
                setComps(new_comps);
            } else if (children[k].type === "signal/table/disableRow") {
                let new_comps = [...comps];
                for (let j = 0; j < new_comps[id].disable[row].length; ++j) {
                    new_comps[id].disable[row][j] = true;
                } setComps(new_comps);
            }
            else if (children[k].type === "signal/table/enable") {
                let new_comps = [...comps];
                new_comps[id].disable[row][parseInt(children[k].column)] = false;
                setComps(new_comps);
            }
        }
    } catch (err) {
        console.error("AtlasScript Compilation Error, KUMAR, > ", err);
    }

}

export default function execute_script(
    eventIndex,
    id,
    comps,
    setComps,
    vars,
    setVars,
    externals,
    setExternals
) {
    let script = comps[id].script[eventIndex];
    if (comps[id]?.type !== "intermediate/InputTable") {
        evaluate(script.children, id, comps, setComps, vars, setVars, externals, setExternals);
    } else {
        for (let k = 0; k < comps[id].data.length; ++k) {
            evaluateTableRow(script.children, id, comps, setComps, vars, setVars, externals, setExternals, k);
        }
    }
}